<template>
  <div class="sw">
    <nav class="topbar" v-if="!getToken">
      <div class="brand" @click="step = 1">
        <router-link to="/" class="brand-icon">
          <img src="@/assets/Logo@2x.png" class="brand-logo" />
        </router-link>
      </div>

      <div class="langue" @click="lang = !lang" ref="langue">
        <p class="langue__title">{{ getSelectedLang.lib }}</p>
        <div class="globe">
          <font-awesome-icon icon="globe" />
        </div>
        <div class="languages" v-show="lang">
          <ul>
            <li
              :class="{ select: lang.iso_code == getSelectedLang.iso_code }"
              @click="chooseLang(lang)"
              v-for="(lang, i) in getActiveLangue"
              :key="i"
            >
              {{ lang.lib }}
            </li>
          </ul>
        </div>
      </div>
    </nav>
    <b-alert variant="success" show dismissible v-if="inscription">
      {{ $t("VALIDATED_REGISTRATION") }}<br />{{ $t("EMAIL_CONFIRMED") }}
    </b-alert>
    <b-alert variant="success" show dismissible v-if="EmailSend">
      {{ $t("EMAIL_CONFIRMED") }}
    </b-alert>
    <b-alert variant="success" show dismissible v-if="passwordReset">
      {{ $t("UPDATE_SUCCESS") }}
    </b-alert>
    <section class="banner-container">
      <div class="banner"></div>
      <div class="banner-content" v-if="step == 1">
        <h1 class="title">{{ $t("BATIGO_WELCOME") }}<span> !</span></h1>

        <p>{{ $t("LOGIN_HELP") }}</p>
        <p class="red" v-if="errorCE">
          {{ $t("EMAIL_NOT_CONFIRMED") }}
          <span @click="resendEmail">{{ $t("SEND_EMAIL") }}</span>
        </p>
        <p class="red" v-if="errorPass">{{ $t("INVALID_LOGIN_PASSWORD") }}</p>
        <b-form @submit.prevent="CurentLogin()">
          <div class="input-class">
            <b-form-input
              class="mt-2"
              v-model="loginFrom.email_u"
              :placeholder="$t('EMAIL')"
              type="email"
              required
            >
            </b-form-input>
          </div>
          <div class="input-class">
            <b-form-input
              class="mt-2"
              v-model="loginFrom.password"
              :placeholder="$t('PASSWORD')"
              autocomplete="off"
              type="password"
              minlength="5"
              required
            >
            </b-form-input>
          </div>
          <b-button class="mt-2" variant="danger" type="submit"
            ><span v-if="!spin">{{ $t("LOGIN") }}</span>
            <div class="text-center" v-if="spin">
              <b-spinner class="w-color"></b-spinner></div
          ></b-button>
        </b-form>
        <b-button
          class="mt-1"
          style="width: 80%"
          variant="success"
          @click="step++"
          >{{ $t("REGISTRATION") }}</b-button
        >
        <p class="forget" @click="step = 5">{{ $t("PASSWORD_FORGOT") }}</p>
      </div>
      <div class="banner-content" v-if="step == 2">
        <div class="register">
          <h2>{{ $t("REGISTRATION") }}</h2>
          <p>1 / 3</p>
        </div>
        <h3>
          <span>1</span>
          {{ $t("INFORMATIONS") }}
        </h3>
        <b-form @submit.prevent="testEmail()">
          <div class="input-group">
            <b-form-input
              class="mt-1"
              oninput="this.value = this.value.toUpperCase()"
              :placeholder="$t('LAST_NAME')"
              v-model="v$.registerFrom.nom.$model"
              :state="validateState('nom')"
              aria-describedby="nom-feedback"
              required
            >
            </b-form-input>
            <error-handle
              :list="v$.registerFrom.nom.$errors"
              id="nom-feedback"
            ></error-handle>
            <div v-if="erreurlist.nom" class="error-message">
              <ul v-if="Array.isArray(erreurlist.nom)">
                <span v-for="(e, index) in erreurlist.nom" :key="index">
                  {{ e }}
                </span>
              </ul>
              <span v-else>{{ erreurlist.nom }}</span>
            </div>
          </div>
          <div class="input-group">
            <b-form-input
              class="mt-1"
              oninput="this.value = this.value.toUpperCase()"
              :placeholder="$t('FIRST_NAME')"
              v-model="v$.registerFrom.prenom.$model"
              :state="validateState('prenom')"
              aria-describedby="prenom-feedback"
              required
            >
            </b-form-input>
            <error-handle
              :list="v$.registerFrom.prenom.$errors"
              id="prenom-feedback"
            ></error-handle>
            <div v-if="erreurlist.prenom" class="error-message">
              <ul v-if="Array.isArray(erreurlist.prenom)">
                <span v-for="(e, index) in erreurlist.prenom" :key="index">
                  {{ e }}
                </span>
              </ul>
              <span v-else>{{ erreurlist.prenom }}</span>
            </div>
          </div>
          <div class="input-group">
            <b-form-input
              class="mt-1"
              v-model="v$.registerFrom.email_u.$model"
              :placeholder="$t('EMAIL')"
              type="email"
              :state="validateState('email_u')"
              aria-describedby="email_u-feedback"
              required
            >
            </b-form-input>
            <div v-if="erreurlist.email_u" class="error-message">
              <ul v-if="Array.isArray(erreurlist.email_u)">
                <span v-for="(e, index) in erreurlist.email_u" :key="index">
                  {{ e }}
                </span>
              </ul>
              <span v-else>{{ erreurlist.email_u }}</span>
            </div>
            <div v-if="emailError" class="error-message">
              {{ $t("EMAIL_USED") }}
            </div>
            <error-handle
              :list="v$.registerFrom.email_u.$errors"
              id="email_u-feedback"
            ></error-handle>
          </div>

          <div class="input-group" style="margin-top: 0.5rem">
            <template>
              <phone
                style="width: 100%"
                :tel="registerFrom.tel_u"
                @phoneNumber="formatNumberU($event)"
              ></phone>
            </template>
            <div
              v-if="registerFrom.tel_u && resultValidU == false"
              class="error-message"
            >
              {{ $t("ERROR_PHONE") }}
            </div>
            <div v-if="erreurlist.tel_u" class="error-message">
              <ul v-if="Array.isArray(erreurlist.tel_u)">
                <span v-for="(e, index) in erreurlist.tel_u" :key="index">
                  {{ e }}
                </span>
              </ul>
              <span v-else>{{ erreurlist.tel_u }}</span>
            </div>
          </div>
          <div class="input-group">
            <b-form-input
              class="mt-1"
              v-model="v$.registerFrom.password.$model"
              :placeholder="$t('PASSWORD')"
              autocomplete="off"
              type="password"
              minlength="5"
              :state="validateState('password')"
              aria-describedby="password-feedback"
              required
            >
            </b-form-input>
            <error-handle
              :list="v$.registerFrom.password.$errors"
              id="password-feedback"
            ></error-handle>
            <div v-if="erreurlist.password" class="error-message">
              <ul v-if="Array.isArray(erreurlist.password)">
                <span v-for="(e, index) in erreurlist.password" :key="index">
                  {{ e }}
                </span>
              </ul>
              <span v-else>{{ erreurlist.password }}</span>
            </div>
          </div>
          <div class="input-group">
            <b-form-input
              class="mt-1"
              :placeholder="$t('PASSWORD_CONFIRM')"
              autocomplete="off"
              type="password"
              v-model="v$.registerFrom.password_confirmation.$model"
              :state="validateState('password_confirmation')"
              aria-describedby="password_confirmation-feedback"
              required
            >
            </b-form-input>
            <error-handle
              :list="v$.registerFrom.password_confirmation.$errors"
              id="password_confirmation-feedback"
            ></error-handle>
            <div v-if="erreurlist.password_confirmation" class="error-message">
              <ul v-if="Array.isArray(erreurlist.password_confirmation)">
                <span
                  v-for="(e, index) in erreurlist.password_confirmation"
                  :key="index"
                >
                  {{ e }}
                </span>
              </ul>
              <span v-else>{{ erreurlist.password_confirmation }}</span>
            </div>
          </div>
          <b-button class="mt-2" variant="success" type="submit">
            {{ $t("NEXT") }}
            <font-awesome-icon style="float: right" icon="chevron-right" />
          </b-button>
          <p
            class="forget"
            @click="step--"
            style="text-align-last: left; width: 80px"
          >
            <font-awesome-icon icon="arrow-left" />
            <span style="margin-left: 2px">{{ $t("PREVIOUS") }}</span>
          </p>
        </b-form>
      </div>

      <div class="banner-content" v-if="step == 3">
        <div class="register">
          <h2>{{ $t("REGISTRATION") }}</h2>
          <p>2 / 3</p>
        </div>
        <h3>
          <span>2</span>
          {{ $t("COMPANY_INFO") }}
        </h3>
        <b-form @submit.prevent="StoreEntreprise()">
          <div class="input-group">
            <b-form-input
              v-model="v$.registerFrom.lib.$model"
              :state="validateState('lib')"
              aria-describedby="lib-feedback"
              oninput="this.value = this.value.toUpperCase()"
              :placeholder="$t('COMPANY_NAME')"
              required
            >
            </b-form-input>
            <error-handle
              :list="v$.registerFrom.lib.$errors"
              id="lib-feedback"
            ></error-handle>
            <div v-if="erreurlist.lib" class="error-message">
              {{ $t("ENTREPRISE_UNIQUE") }}
            </div>
          </div>
          <div class="input-group">
            <b-form-input
              class="mt-1"
              type="email"
              :placeholder="$t('COMPANY_EMAIL')"
              v-model="v$.registerFrom.email.$model"
              :state="validateState('email')"
              aria-describedby="email-feedback"
              required
            >
            </b-form-input>
            <error-handle
              :list="v$.registerFrom.email.$errors"
              id="email-feedback"
            ></error-handle>
            <div v-if="erreurlist.email" class="error-message">
              {{ $t("EMAIL_USED") }}
            </div>
          </div>
          <div class="input-group">
            <b-form-select
              class="mt-1"
              v-model="registerFrom.pays"
              :options="getPays"
              text-field="name"
              value-field="iso_code"
              required
            >
              <template #first>
                <b-form-select-option :value="null" disabled
                  >-- {{ $t("COUNTRY") }} --</b-form-select-option
                >
              </template>
            </b-form-select>
            <div v-if="erreurlist.pays" class="error-message">
              <ul v-if="Array.isArray(erreurlist.pays)">
                <span v-for="(e, index) in erreurlist.pays" :key="index">
                  {{ e }}
                </span>
              </ul>
              <span v-else>{{ erreurlist.pays }}</span>
            </div>
          </div>
          <div class="input-group">
            <b-form-input
              class="mt-1"
              oninput="this.value = this.value.toUpperCase()"
              :placeholder="$t('CITY')"
              v-model="v$.registerFrom.ville.$model"
              :state="validateState('ville')"
              aria-describedby="ville-feedback"
              required
            >
            </b-form-input>
            <error-handle
              :list="v$.registerFrom.ville.$errors"
              id="ville-feedback"
            ></error-handle>
            <div v-if="erreurlist.ville" class="error-message">
              <ul v-if="Array.isArray(erreurlist.ville)">
                <span v-for="(e, index) in erreurlist.ville" :key="index">
                  {{ e }}
                </span>
              </ul>
              <span v-else>{{ erreurlist.ville }}</span>
            </div>
          </div>
          <div class="input-group" style="margin-top: 0.5rem">
            <template>
              <phone
                style="width: 100%"
                :tel="registerFrom.tel"
                @phoneNumber="formatNumberE($event)"
              ></phone>
            </template>

            <div
              v-if="registerFrom.tel && resultValidE == false"
              class="error-message"
            >
              {{ $t("ERROR_PHONE") }}
            </div>
            <div v-if="erreurlist.tel" class="error-message">
              <ul v-if="Array.isArray(erreurlist.tel)">
                <span v-for="(e, index) in erreurlist.tel" :key="index">
                  {{ e }}
                </span>
              </ul>
              <span v-else>{{ erreurlist.tel }}</span>
            </div>
          </div>
          <div class="input-group">
            <b-form-input
              class="mt-1"
              v-model="v$.registerFrom.rue.$model"
              :state="validateState('rue')"
              aria-describedby="rue-feedback"
              :placeholder="$t('STREET')"
              required
            >
            </b-form-input>
            <error-handle
              :list="v$.registerFrom.rue.$errors"
              id="rue-feedback"
            ></error-handle>
            <div v-if="erreurlist.rue" class="error-message">
              <ul v-if="Array.isArray(erreurlist.rue)">
                <span v-for="(e, index) in erreurlist.rue" :key="index">
                  {{ e }}
                </span>
              </ul>
              <span v-else>{{ erreurlist.rue }}</span>
            </div>
          </div>
          <div class="input-group">
            <b-form-input
              class="mt-1"
              :placeholder="$t('POSTAL_CODE')"
              v-model="v$.registerFrom.cp.$model"
              :state="validateState('cp')"
              aria-describedby="cp-feedback"
              required
            >
            </b-form-input>
            <error-handle
              :list="v$.registerFrom.cp.$errors"
              id="cp-feedback"
            ></error-handle>
            <div v-if="erreurlist.cp" class="error-message">
              <ul v-if="Array.isArray(erreurlist.cp)">
                <span v-for="(e, index) in erreurlist.cp" :key="index">
                  {{ e }}
                </span>
              </ul>
              <span v-else>{{ erreurlist.cp }}</span>
            </div>
          </div>
          <div class="input-group">
            <b-form-input
              class="mt-1"
              :placeholder="$t('Rcs')"
              v-model="v$.registerFrom.rcs.$model"
              :state="validateState('rcs')"
              aria-describedby="rcs-feedback"
              required
            >
            </b-form-input>
            <error-handle
              :list="v$.registerFrom.rcs.$errors"
              id="rcs-feedback"
            ></error-handle>
            <div v-if="erreurlist.rcs" class="error-message">
              <ul v-if="Array.isArray(erreurlist.rcs)">
                <span v-for="(e, index) in erreurlist.rcs" :key="index">
                  {{ e }}
                </span>
              </ul>
              <span v-else>{{ erreurlist.rcs }}</span>
            </div>
          </div>

          <b-button class="mt-2" variant="success" type="submit">
            {{ $t("NEXT") }}
            <font-awesome-icon style="float: right" icon="chevron-right" />
          </b-button>
          <!-- <p
            class="forget"
            @click="step--"
            style="text-align-last: left; width: 80px"
          >
            <font-awesome-icon icon="arrow-left" />
            <span style="margin-left: 2px">{{ $t("PREVIOUS") }}</span>
          </p> -->
        </b-form>
      </div>

      <div class="banner-content" v-if="step == 4" style="min-height: 450px">
        <div class="register">
          <h2>{{ $t("REGISTRATION") }}</h2>
          <p>3 / 3</p>
        </div>
        <h3>
          <span>3</span>
          {{ $t("Vous travaillez ?") }}
        </h3>
        <b-form @submit.prevent="HandleVisibility()" class="formMetier">
          <searchBar
            :placeholder="$t('CATEGORY_SEARCH')"
            :searchFunc="allcategorieMetier"
            :showSearch="false"
          ></searchBar>
          <div class="categorie">
            <div
              v-for="metier in computedMetier"
              :key="metier.id"
              class="metier"
              :class="{ selected: findItem(metier) >= 0 }"
              @click="addmetier(metier)"
            >
              {{ metier.lib }}
            </div>
            <div
              class="metier"
              style="width: 100%"
              v-if="minMetier && getAllcategorieMetier.length > 6"
              @click="minMetier = !minMetier"
            >
              Autres
            </div>
            <div
              v-else-if="!minMetier && getAllcategorieMetier.length > 6"
              class="metier"
              style="width: 100%"
              @click="minMetier = !minMetier"
            >
              Moins
            </div>
          </div>

          <b-button
            class="mt-1"
            variant="success"
            type="submit"
            style="display: flex; justify-content: center; align-items: center"
          >
            {{ $t("CONFIRM") }}
            <div v-if="getOnlineUserLoading" class="loading ml-2">
              <div class="spinner-border" role="status"></div>
            </div>
          </b-button>
        </b-form>
      </div>

      <div class="banner-content" v-if="step == 5">
        <div class="register">
          <h2>{{ $t("PASSWORD_UPDATE") }}</h2>
        </div>
        <b-form @submit.prevent="forgotPassword()" v-if="!email_send">
          <h4 class="font-weight-bold">{{ $t("PASSWORD_FORGOT") }}</h4>
          <h5>{{ $t("EMAIL") }}</h5>
          <b-form-group class="reset">
            <b-form-input
              id="input-2"
              v-model="resetFrom.email_u"
              type="email"
              :placeholder="$t('EMAIL')"
              required
            ></b-form-input>
          </b-form-group>

          <div v-if="errorFP" class="error-message">
            {{ errorFP }}
          </div>
          <b-button class="mt-2" variant="success" type="submit"
            ><span v-if="!getOnlineUserLoading">{{
              $t("PASSWORD_UPDATE")
            }}</span>
            <div class="text-center" v-if="getOnlineUserLoading">
              <b-spinner class="w-color"></b-spinner></div
          ></b-button>
          <p
            class="forget"
            @click="step = 1"
            style="text-align-last: left; width: 80px"
          >
            <font-awesome-icon icon="arrow-left" />
            <span style="margin-left: 2px">{{ $t("PREVIOUS") }}</span>
          </p>
        </b-form>
        <div class="valider" v-else>
          <font-awesome-icon icon="check-circle" class="iconv" />
          {{ $t("EMAIL_PASSWORD_FORGOT") }}
          <b-button
            @click="
              step = 1;
              email_send = false;
            "
            class="mt-2"
            variant="success"
            >{{ $t("VALIDATE") }}</b-button
          >
        </div>
      </div>

      <div class="banner-content" v-if="step == 6">
        <div class="register">
          <h2>{{ $t("PASSWORD_UPDATE") }}</h2>
        </div>
        <b-form @submit.prevent="ResetPassword()">
          <b-form-group :label="$t('EMAIL')" class="reset">
            <b-form-input
              v-model="resetFrom.email_u"
              type="email"
              :placeholder="$t('EMAIL')"
              required
              readonly
            ></b-form-input>
          </b-form-group>
          <b-form-group :label="$t('PASSWORD')" class="reset">
            <b-form-input
              id="input-2"
              autocomplete="off"
              type="password"
              :placeholder="$t('PASSWORD')"
              v-model="v$.resetFrom.password.$model"
              :state="validateResetState('password')"
              aria-describedby="password-feedback"
              required
            ></b-form-input>
            <error-handle
              :list="v$.resetFrom.password.$errors"
              id="password-feedback"
            ></error-handle>
          </b-form-group>
          <b-form-group :label="$t('PASSWORD_CONFIRM')" class="reset">
            <b-form-input
              v-model="v$.resetFrom.password_confirmation.$model"
              autocomplete="off"
              type="password"
              :placeholder="$t('PASSWORD_CONFIRM')"
              :state="validateResetState('password_confirmation')"
              aria-describedby="password_confirmation-feedback"
              required
            ></b-form-input>
            <error-handle
              :list="v$.resetFrom.password_confirmation.$errors"
              id="password_confirmation-feedback"
            ></error-handle>
          </b-form-group>

          <div class="error-message">{{ errorU }}</div>

          <b-button class="mt-2" variant="success" type="submit"
            ><span v-if="!getOnlineUserLoading">{{
              $t("PASSWORD_UPDATE")
            }}</span>
            <div class="text-center" v-if="getOnlineUserLoading">
              <b-spinner class="w-color"></b-spinner></div
          ></b-button>
        </b-form>
      </div>
    </section>
    <!-- <section class="banner-bottom">
      <hr class="red" />
      <div class="link">
        <router-link to="/about_us">{{ $t("ABOUT_US") }}</router-link>
        <router-link to="/privacy">{{ $t("LEGAL_MENTION") }}</router-link>
        <router-link to="/cgu">{{ $t("C.G.U") }}</router-link>
      </div>
      <p>{{ $t("COPYRIGHT") }}</p>
    </section> -->
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import "vue-phone-number-input/dist/vue-phone-number-input.css";
import { useVuelidate } from "@vuelidate/core";
import {
  required,
  email,
  sameAs,
  maxLength,
  minLength,
  alphaNum,
  numeric,
} from "@vuelidate/validators";
import errorHandle from "../components/ui/errorHandle.vue";
import phone from "../components/ui/phone.vue";
import searchBar from "../components/ui/searchBar.vue";

export default {
  setup() {
    return { v$: useVuelidate({ $lazy: true, $autoDirty: true }) };
  },
  components: {
    searchBar,
    errorHandle,
    phone,
  },
  data() {
    return {
      step: 1,
      inscription: false,
      emailError: false,
      EmailSend: false,
      loginFrom: {
        email_u: null,
        password: null,
      },
      minMetier: true,
      metiers: [],
      cat_metier: [
        { lib: "Salon", id: 1 },
        { lib: "Maison", id: 2 },
        { lib: "Loisirs", id: 3 },
        { lib: "SalonSalonSalonSalonSalonSalonSalon", id: 4 },
        { lib: "Maison", id: 5 },
        { lib: "Loisirs", id: 6 },
        { lib: "Salon", id: 7 },
        { lib: "Maison", id: 8 },
        { lib: "Loisirs", id: 9 },
        { lib: "Salon", id: 10 },
      ],
      registerFrom: {
        nom: null,
        prenom: null,
        email_u: null,
        password: null,
        password_confirmation: null,
        tel_u: {
          phone_number: null,
          phone_iso_code: null,
        },
        lib: null,
        email: null,
        rue: null,
        cp: null,
        ville: null,
        pays: null,
        tel: {
          phone_number: null,
          phone_iso_code: null,
        },
        rcs: null,
      },
      resetFrom: {
        email_u: "",
        token: "",
        password: "",
        password_confirmation: "",
      },
      erreurlist: {
        nom: null,
        prenom: null,
        email_u: null,
        tel_u: null,
        password: null,
        password_confirmation: null,
        lib: null,
        email: null,
        pays: null,
        ville: null,
        rue: null,
        tel: null,
        cp: null,
        rcs: null,
        rue_u: null,
      },
      spin: false,
      errorCE: false,
      errorPass: false,
      email_send: false,
      lang: 0,
      registerError: null,
      passwordReset: false,
      errorU: null,
      resultValidU: null,
      resultValidE: null,
      vueTel: {
        preferredCountries: ["FR", "TN"],
        mode: "auto",
        disabledFormatting: false,
        validCharactersOnly: true,
        wrapperClasses: "country-phone-input",
      },
      errorFP: null,
    };
  },
  validations() {
    return {
      registerFrom: {
        nom: { required, maxLength: maxLength(100) },
        prenom: { required, maxLength: maxLength(100) },
        email_u: { required, email, maxLength: maxLength(50) },
        password: {
          required,
          minLength: minLength(6),
          maxLength: maxLength(30),
        },
        password_confirmation: {
          required,
          sameAs: sameAs(this.registerFrom.password),
        },
        tel_u: { required },
        lib: { required, maxLength: maxLength(40) },
        email: { required, email, maxLength: maxLength(50) },
        rue: { required, maxLength: maxLength(40) },
        cp: {
          required,
          numeric,
          minLength: minLength(4),
          maxLength: maxLength(5),
        },
        pays: { required },
        ville: { required, maxLength: maxLength(100) },
        rcs: { required, maxLength: maxLength(50) },
        titulaire_compte: { maxLength: maxLength(40) },
        SWIFT_BIC: {
          alphaNum,
          minLength: minLength(8),
          maxLength: maxLength(11),
        },
        IBAN: { maxLength: maxLength(34), alphaNum },
        num_tva: { maxLength: maxLength(30), alphaNum },
        siret: {
          minLength: minLength(9),
          maxLength: maxLength(14),
          alphaNum,
        },
      },
      resetFrom: {
        password: { required, minLength: minLength(6) },
        password_confirmation: {
          required,
          sameAs: sameAs(this.resetFrom.password),
        },
      },
    };
  },
  methods: {
    ...mapActions([
      "login",
      "register",
      "entreprise_register",
      "forgot_password",
      "reset_password",
      "changeLang",
      "pays",
      "verifyEmail",
      "resend_active_email",
      "allcategorieMetier",
      "visibilite_metier",
      "store_visibilite_categorie_metier",
      "onlineUser",
    ]),

    validateState(name) {
      const { $dirty, $error } = this.v$.registerFrom[name];
      return $dirty ? !$error : null;
    },

    validateResetState(name) {
      const { $dirty, $error } = this.v$.resetFrom[name];
      return $dirty ? !$error : null;
    },

    async CurentLogin() {
      this.spin = true;
      this.payload = this.loginFrom;
      await this.login(this.payload)
        .then((response) => {
          this.spin = false;
          if (!response.message) {
            this.errorCE = false;
            this.errorPass = false;
            if (this.getOnlineUser.entreprise) {
              this.visibilite_metier().then(() => {
                if (this.getAllmetierV.length) this.$router.push("/");
                else {
                  this.allcategorieMetier({
                    page: 1,
                    per_page: 1000,
                  });
                  this.step = 4;
                }
              });
            } else this.step = 3;
          } else if (response.message == "Unauthorized") {
            this.errorCE = false;
            this.errorPass = true;
          } else {
            this.errorCE = true;
            this.errorPass = false;
          }
        })
        .catch(() => {
          this.errorCE = false;
          this.errorPass = true;
        });
    },

    resendEmail() {
      this.resend_active_email(this.loginFrom).then(() => {
        this.EmailSend = true;
      });
    },
    StoreEntreprise() {
      const { lib, email, ville, rue, cp, rcs } = this.v$.registerFrom;

      lib.$validate();
      email.$validate();
      ville.$validate();
      rue.$validate();
      cp.$validate();
      rcs.$validate();

      if (
        lib.$error ||
        email.$error ||
        ville.$error ||
        rue.$error ||
        cp.$error ||
        rcs.$error ||
        !this.resultValidE
      )
        return;

      this.resetErrorList();
      var num = this.registerFrom.tel.phone_number.indexOf(" ");
      var phone_number = this.registerFrom.tel?.phone_number
        .slice(num + 1)
        .split(" ")
        .join("");

      this.entreprise_register({
        lib: this.registerFrom.lib,
        email: this.registerFrom.email,
        pays: this.registerFrom.pays,
        ville: this.registerFrom.ville,
        entreprise_phone_number: phone_number,
        entreprise_phone_iso_code: this.registerFrom.tel?.phone_iso_code,
        rue: this.registerFrom.rue,
        cp: this.registerFrom.cp,
        rcs: this.registerFrom.rcs,
      })
        .then(() => {
          this.allcategorieMetier({
            page: 1,
            per_page: 1000,
          });
          this.onlineUser();
          this.step++;
        })
        .catch((err) => {
          if (this.isObject(err)) {
            this.registerError = this.$t("ERROR");
            for (const [key, value] of Object.entries(err)) {
              if (!this.erreurlist[key]) {
                this.erreurlist[key] = value;
              }
            }
          } else {
            this.registerError = err;
          }
        });
    },
    async testEmail() {
      const { nom, prenom, email_u, password, password_confirmation } =
        this.v$.registerFrom;
      nom.$validate();
      prenom.$validate();
      email_u.$validate();
      password.$validate();
      password_confirmation.$validate();

      if (
        nom.$error ||
        prenom.$error ||
        email_u.$error ||
        password.$error ||
        password_confirmation.$error ||
        !this.resultValidU
      )
        return;

      this.resetErrorList();

      this.verifyEmail(this.registerFrom.email_u)
        .then((response) => {
          if (!response) {
            this.emailError = false;
          } else {
            this.emailError = true;
            return;
          }
        })
        .catch(() => {
          this.emailError = true;
          return;
        });

      var num = this.registerFrom.tel_u.phone_number.indexOf(" ");
      var phone_number = this.registerFrom.tel_u?.phone_number
        .slice(num + 1)
        .split(" ")
        .join("");

      this.register({
        nom: this.registerFrom.nom,
        prenom: this.registerFrom.prenom,
        email_u: this.registerFrom.email_u,
        tel_u: this.registerFrom.tel_u,
        phone_number: phone_number,
        phone_iso_code: this.registerFrom.tel_u?.phone_iso_code,
        password: this.registerFrom.password,
        password_confirmation: this.registerFrom.password_confirmation,
      })
        .then(() => {
          this.login(this.registerFrom).then(() => {
            this.allcategorieMetier({
              page: 1,
              per_page: 1000,
            });
            this.step++;
          });
        })
        .catch((err) => {
          if (this.isObject(err)) {
            this.registerError = this.$t("ERROR");
            for (const [key, value] of Object.entries(err)) {
              if (!this.erreurlist[key]) {
                this.erreurlist[key] = value;
              }
            }
          } else {
            this.registerError = err;
          }
        });
    },

    formatNumberU(event) {
      this.registerFrom.tel_u = {
        phone_number: event.number,
        phone_iso_code: event.country.iso2,
      };
      this.registerFrom.phone_iso_code = event.country.iso2;
      this.resultValidU = event.isValid;
    },

    formatNumberE(event) {
      this.registerFrom.tel = {
        phone_number: event.number,
        phone_iso_code: event.country.iso2,
      };
      this.registerFrom.entreprise_phone_iso_code = event.country.iso2;
      this.resultValidE = event.isValid;
    },

    findItem(item) {
      const find = this.metiers.findIndex((key) => key == item.id);
      return find;
    },

    addmetier(item) {
      const find = this.findItem(item);
      if (find < 0) {
        this.metiers.push(item.id);
      } else {
        this.metiers.splice(find, 1);
      }
    },

    checkU(number, event) {
      this.resultValidU = event.isValid;
    },

    checkE(number, event) {
      this.resultValidE = event.isValid;
    },

    HandleVisibility() {
      if (!this.metiers.length) {
        this.$bvModal.msgBoxOk(this.$t("METIER_REQUIRED"), {
          title: this.$t("ALERT"),
          size: "sm",
          buttonSize: "sm",
          okVariant: "success",
          headerClass: "p-2 border-bottom-0",
          footerClass: "p-2 border-top-0",
          centered: true,
        });
        return;
      }
      this.store_visibilite_categorie_metier({
        categorie_metier_id: this.metiers,
      }).then(() => {
        this.visibilite_metier();
        this.step = 1;
        this.registerError = null;
        this.inscription = true;
        this.$router.push("/Entreprise_information");
      });
    },

    resetErrorList() {
      this.erreurlist = {
        nom: null,
        prenom: null,
        email_u: null,
        tel_u: null,
        password: null,
        password_confirmation: null,
        lib: null,
        email: null,
        pays: null,
        ville: null,
        rue: null,
        tel: null,
        cp: null,
        rcs: null,
        titulaire_compte: null,
        SWIFT_BIC: null,
        IBAN: null,
        siret: null,
        num_tva: null,
        rue_u: null,
      };
      this.registerError = null;
    },

    async CurentRegister() {
      await this.v$.$validate();
      const isFormCorrect = await this.v$.registerFrom.$error;
      if (isFormCorrect) {
        return;
      }

      // Format numero tel
      var num = this.registerFrom.tel_u.phone_number.indexOf(" ");

      this.registerFrom.phone_number = this.registerFrom.tel_u.phone_number
        .slice(num + 1)
        .split(" ")
        .join("");

      // Format numero tel company
      var numE = this.registerFrom.tel.phone_number.indexOf(" ");
      this.registerFrom.entreprise_phone_number =
        this.registerFrom.tel.phone_number
          .slice(numE + 1)
          .split(" ")
          .join("");

      await this.register(this.registerFrom)
        .then((response) => {
          if (response) {
            this.step = 1;
            this.registerError = null;
            this.inscription = true;
            this.login(this.registerFrom);
          }
        })
        .catch((err) => {
          if (this.isObject(err)) {
            this.registerError = this.$t("ERROR");
            for (const [key, value] of Object.entries(err)) {
              if (!this.erreurlist[key]) {
                this.erreurlist[key] = value;
              }
            }
          } else {
            this.registerError = err;
          }
        });
    },
    isObject(obj) {
      return Object.prototype.toString.call(obj) === "[object Object]";
    },
    async forgotPassword() {
      await this.forgot_password(this.resetFrom.email_u)
        .then(() => {
          this.email_send = true;
        })
        .catch((err) => {
          this.errorFP = err;
        });
    },
    async ResetPassword() {
      const { password, password_confirmation } = this.v$.resetFrom;

      password.$validate();
      password_confirmation.$validate();

      if (password.$error || password_confirmation.$error) return;

      await this.reset_password(this.resetFrom)
        .then(() => {
          this.errorU = null;
          this.passwordReset = true;
          this.step = 1;
        })
        .catch(() => {
          this.errorU = this.$t("ERROR");
        });
    },

    chooseLang(lang) {
      this.changeLang(lang);
      this.paysLang();
    },

    paysLang() {
      var lang;
      switch (this.getSelectedLang.iso_code) {
        case "fr":
          lang = "fr";
          break;
        case "prt":
          lang = "pt";
          break;
        case "IT":
          lang = "it";
          break;
        case "ar":
          lang = "fa";
          break;
        case "Gr":
          lang = "de";
          break;
        default:
          lang = "en";
      }
      this.pays(lang);
    },

    close(e) {
      if (this.$refs["langue"])
        if (!this.$refs["langue"].contains(e.target)) {
          this.lang = 0;
        }
    },
  },

  computed: {
    ...mapGetters([
      "getOnlineUserLoading",
      "getToken",
      "getOnlineUser",
      "getPays",
      "getKey",
      "getAllLangue",
      "getSelectedLang",
      "getdictionnaire",
      "getActiveLangue",
      "getAllcategorieMetier",
      "getAllmetierV",
    ]),
    translations() {
      var trans = {
        countrySelectorLabel: "Choisir pays",
        phoneNumberLabel: "Numéro téléphone",
      };
      if (this.getdictionnaire) {
        this.getdictionnaire.forEach((element) => {
          if (element.key == "countrySelectorLabel")
            trans.countrySelectorLabel = element.lib;
          if (element.key == "phoneNumberLabel")
            trans.phoneNumberLabel = element.lib;
        });
      }

      return trans;
    },
    computedMetier() {
      var liste = this.getAllcategorieMetier;
      if (this.minMetier) return this.getAllcategorieMetier.slice(0, 6);
      return liste;
    },
  },

  mounted() {
    if (this.$route.params.token) {
      this.step = 6;
      this.resetFrom.email_u = this.$route.query.email_u;
      this.resetFrom.token = this.$route.params.token;
    }
    this.paysLang();
    if (this.getOnlineUser) {
      this.onlineUser();
      if (!this.getOnlineUser.entreprise) this.step = 3;
      else {
        this.visibilite_metier().then(() => {
          if (this.getAllmetierV.length) this.$router.push("/");
          else {
            this.allcategorieMetier({
              page: 1,
              per_page: 1000,
            });
            this.step = 4;
          }
        });
      }
    }
    document.addEventListener("click", this.close);
  },

  beforeDestroy() {
    document.removeEventListener("click", this.close);
  },
};
</script>

<style lang="scss" scoped>
.alert-success,
.alert-warning {
  position: fixed;
  top: 55px;
  left: 0;
  right: 0;
  z-index: 1000;
  text-align: center;
}
.sw {
  width: 100%;
  position: relative;
}
.brand {
  @media only screen and (max-width: 900px) {
    order: 0 !important;
  }
}
.brand-logo {
  width: 84px;
  height: 32px;
}
button {
  width: 80%;
  height: 42px;
  font-size: 14px;
}
.banner-container {
  position: relative;
  background: url(../assets/reunion-du-groupe.jpg) top center/cover no-repeat;
  height: calc(100vh - 55px);
  display: flex !important;
  align-items: center;
  justify-content: flex-end;
  z-index: 0;

  .banner {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    background: url(../assets/banner.png);
    height: calc(100vh - 55px);
    z-index: -1;
  }

  .banner-content {
    @media only screen and (max-width: 900px) {
      width: 45%;
      margin: auto;
    }

    @media only screen and (max-width: 500px) {
      width: 90%;
      min-width: 90%;
      margin: auto;
    }

    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
    margin-top: 42px;
    min-width: 420px;
    max-width: 480px;
    padding: 42px 16px;
    background-color: #fff;
    margin-right: 5%;
    border-radius: 10px;
    overflow-y: auto;
    max-height: 75vh;

    h3 {
      display: flex;
      align-items: center;
      font-size: 14px;
      font-weight: bold;
      color: #28367a;
      align-self: flex-start;
      width: 80%;
      margin: 8px auto;
      span {
        display: block;
        margin-right: 4px;
        background-color: #e4261b;
        color: #fff;
        border-radius: 50%;
        width: 20px;
        height: 20px;
        font-size: 11px;
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }

    h4 {
      margin: 24px 0 4px;
      font-size: 16px;
    }
    h5 {
      margin: 0 0 32px;
      font-size: 14px;
    }

    button {
      width: 100%;
    }

    .title {
      text-align: center;
      color: #28367a;
      font-size: 22px;
      line-height: 0.4;
      font-weight: 400;

      span {
        font-weight: 600;
        font-size: 28px;
      }
    }

    .red {
      font-size: 14px;
      margin-top: 24px;
      span {
        display: block;
        text-align: center;
        cursor: pointer;
        font-size: 10px;
        font-weight: 400;
        color: #28367a;
        text-decoration: underline;
      }
    }

    p {
      margin: 0;
      font-size: 12px;
      color: #0f0f0f;
    }

    .register {
      display: flex;
      justify-content: space-between;
      position: relative;
      width: 80%;
      margin-bottom: 8px;

      & h2 {
        font-size: 14px;
        color: #28367a;
        margin: 0;
        &::after {
          content: "";
          position: absolute;
          bottom: -2px;
          left: 0;
          width: 75px;
          border-bottom: 4px solid #e4261b;
        }
      }

      & p {
        margin: 0;
        font-size: 14px;
        color: #28367a;
      }
    }
  }

  form {
    width: 80%;
    .input-class {
      input {
        background-color: #f9f9f9;
        font-size: 14px;
        padding: 10px;
        height: auto;
        margin-top: 18px !important;
        color: #000;
        border: none;

        &::placeholder {
          color: #5d5d5d;
          font-size: 12px;
        }
      }
    }
    .input-group {
      // margin-top: 0.5rem;
      position: relative;
      & input {
        border-radius: 5px !important;
        font-size: 14px;
        color: #28367a;
      }

      .form-control {
        width: 100%;
        padding: 8px 24px;
        border: 1.5px solid #b5b5b5;
        &::placeholder {
          color: #5d5d5d;
          padding: 8px 0px;
          font-size: 12px;
        }
      }
    }
  }

  .forget {
    cursor: pointer;
    margin: 8px 0 0 !important;
    color: red;
    font-size: 12px !important;
    font-weight: 400;
    color: #28367a !important;
    text-align: center;
    text-decoration: underline;

    &:hover {
      color: #0f0f0f;
    }
  }
}

.valider {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  & h4 {
    font-size: 18px;
    font-weight: 600;
    color: #28367a;
  }

  & p {
    font-size: 12px;
    font-weight: 600;
  }

  & .iconv {
    width: 50%;
    height: 116px;
    color: #52a500;
    margin-bottom: 24px;
  }
}

.banner-bottom {
  @media only screen and (max-width: 700px) {
    flex-direction: column;
    position: fixed;
    bottom: 0px;
    left: 0;
    right: 0;
  }
  position: relative;
  background-color: #28367a;
  min-height: 55px;
  display: flex !important;
  justify-content: space-around;
  align-items: center;
  color: #fff;

  .red {
    @media only screen and (max-width: 900px) {
      border-top: 3px solid #e4261b;
      top: -3px;
    }
    position: absolute;
    width: 25%;
    height: 0px;
    border: none;
    border-top: 5px solid #e4261b;
    bottom: 0;
    left: 0;
    margin: 0;
  }
  .link {
    @media only screen and (max-width: 900px) {
      width: 100%;
      justify-content: space-around;
    }
    width: 40%;
    display: flex;
    justify-content: space-between;

    & a {
      text-decoration: underline;
      color: #fff;
      font-size: 14px;

      &:hover {
        color: #ffffff80;
      }
    }
  }
  p {
    font-size: 12px;
    margin: 0;
  }
}

.messageError {
  color: #e4261b;
  font-size: 14px;
}

.formMetier {
  @media only screen and (max-width: 900px) {
    width: 100% !important;
  }
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex: 1;

  .forget {
    margin: 0 !important;
  }
}
.categorie {
  @media only screen and (max-width: 900px) {
    gap: 6px 0;
  }
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  gap: 6px;
  margin-bottom: 8px;
  max-height: 204px;
  overflow-y: auto;
  flex: 1;

  /* Largeur de la barre de défilement */
  &::-webkit-scrollbar {
    width: 10px;
  }

  /* Fond de la barre de défilement */
  &::-webkit-scrollbar-track {
    background-color: #f1f1f1;
  }

  /* Couleur de la poignée de la barre de défilement */
  &::-webkit-scrollbar-thumb {
    background-color: #888;
    border-radius: 5px;
  }

  /* Hover sur la poignée de la barre de défilement */
  &::-webkit-scrollbar-thumb:hover {
    background-color: #555;
  }
}

.selected {
  background-color: #5c6bb4;
  border-color: #5c6bb4 !important;
  color: #fff !important;
}
.metier {
  width: 49%;
  height: 36px;
  font-size: 12px;
  border: 1px solid #28367a;
  color: #28367a;
  padding: 0 8px;
  text-align: center;
  line-height: 36px;
  cursor: pointer;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
</style>
